
  
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_FORGOTPASSWORD_REQUEST = 'USER_FORGOTPASSWORD_REQUEST'
export const USER_FORGOTPASSWORD_SUCCESS = 'USER_FORGOTPASSWORD_SUCCESS'
export const USER_FORGOTPASSWORD_FAIL = 'USER_FORGOTPASSWORD_FAIL'
export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST'
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS'
export const NOTIFICATION_FAIL = 'NOTIFICATION_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS'
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL'
